.about {
	// text-align: center;
	background: #111;
	width: 100vw;
	min-height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
  display: flex;
  flex-direction: row;
	justify-content: space-around;
	align-items: center;
  padding: 10vw;
  flex-wrap: wrap;
  .home-button {
    position: absolute;
    left: 2rem;
		top: 2rem;
		cursor: pointer;
    i {
      text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
        0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
      color: #fed128;
      &:hover {
        animation: button-flicker-1 2s linear;
        animation-fill-mode: forwards;
      }
    }
  }
  div {
    flex: 1;
  }
	h1 {
    display: inline-block;
		font-family: neon2, cursive;
		color: white;
		font-size: 2em;
		margin-top: 2em;
  }
  h3 {
    display: inline-block;
    font-family: sans-serif;
    animation: none;
    color: white;
    line-height: 5rem;
    font-size: 1.2rem;
    min-width: 300px;
  }
}

#piano {
  filter: drop-shadow(0 0 2vw #cd669a) drop-shadow(0 0 0.4vw #fb9dcc)
    drop-shadow(0.5vw 0.5vw 0.1vw #b30c5f);
  width: 45%;
  min-width: 300px;
  margin: 3rem;
}