@import url(https://rsms.me/inter/inter-ui.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: #111; }

.fade-enter,
.fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1s ease opacity;
  transition: 1s ease opacity; }

.fade-enter,
.fade-exit-active {
  opacity: 0; }

.fade-enter-active {
  opacity: 1;
  z-index: 1; }

.hide-loading {
  display: none; }

/* for showing/hiding pages */
.show {
  pointer-events: all;
  opacity: 1;
  -webkit-transition: 1s;
  transition: 1s;
  height: auto;
  overflow: auto; }

.hide {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 1s;
  transition: 1s;
  height: 0px;
  overflow: hidden; }

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .03;
  background: url("/static/media/neon background better.e10987a5.svg");
  background-size: cover; }

.App {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-size: cover; }
  .App .menu-container {
    color: #fafafa;
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 2;
    display: none; }
  .App .fullscreen-menu {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: #333333ee;
    border-radius: 50% 0 0 50%;
    min-height: 100%;
    width: 0;
    overflow: hidden;
    -webkit-transition: 1s;
    transition: 1s; }
    .App .fullscreen-menu h3 {
      color: white;
      font-family: neon2, cursive;
      font-size: 10vmin;
      line-height: 20vmin; }
      .App .fullscreen-menu h3:hover {
        color: #fed128; }
    .App .fullscreen-menu.show {
      width: 100vw;
      border-radius: 0;
      overflow: scroll; }
  .App > h2 {
    position: absolute;
    font-family: neon2, cursive;
    font-size: calc(1em + 1vw);
    width: 200px;
    cursor: pointer;
    background: #111;
    padding: 15px; }
    .App > h2:nth-last-of-type(1) {
      z-index: 0; }
  .App .name {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .App .name > h1 {
      margin: 0;
      font-family: neon, cursive;
      font-size: 6.5vw;
      text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
      color: #f372ff;
      animation: neon 10s linear infinite alternate-reverse;
      -webkit-animation: flicker 1s infinite;
              animation: flicker 1s infinite; }
    .App .name:hover h1 {
      -webkit-animation: neon-hover 3s linear;
              animation: neon-hover 3s linear;
      -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
      cursor: default; }
    .App .name .blackout {
      -webkit-animation: blackout 1.5s linear;
              animation: blackout 1.5s linear;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
  .App h2:nth-last-of-type(1) {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff;
    animation: neon 10s linear infinite alternate-reverse;
    -webkit-animation: flicker 1s infinite;
            animation: flicker 1s infinite;
    -webkit-animation-delay: .2s;
            animation-delay: .2s; }
  .App .welcome-message {
    color: white;
    width: auto;
    position: static;
    margin-top: 10vh;
    cursor: default; }
  .App .blackout {
    -webkit-animation: blackout 1.5s linear !important;
            animation: blackout 1.5s linear !important;
    -webkit-animation-fill-mode: forwards !important;
            animation-fill-mode: forwards !important; }
  .App > .top {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: 20px; }
  .App > .bottom {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    bottom: 20px; }
  .App > .right {
    right: -50px;
    top: 50%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .App > .left {
    left: -60px;
    top: 50%;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .App .top, .App .bottom {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128; }
    .App .top:hover, .App .bottom:hover {
      -webkit-animation: button-flicker-1 2s linear;
              animation: button-flicker-1 2s linear;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
  .App .right {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw -0.5vw 0.1vw #806914;
    color: #fed128; }
    .App .right:hover {
      -webkit-animation: button-flicker-2 2s linear;
              animation: button-flicker-2 2s linear;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
  .App .left {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, -0.5vw 0.5vw 0.1vw #806914;
    color: #fed128; }
    .App .left:hover {
      -webkit-animation: button-flicker-3 2s linear;
              animation: button-flicker-3 2s linear;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }

@-webkit-keyframes button-flicker-1 {
  0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fff000; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914; } }

@keyframes button-flicker-1 {
  0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fff000; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914; } }

@-webkit-keyframes button-flicker-2 {
  0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw -0.5vw 0.1vw #806914;
    color: #fff000; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw -0.5vw 0.1vw #40340a;
    color: #806914; } }

@keyframes button-flicker-2 {
  0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw -0.5vw 0.1vw #806914;
    color: #fff000; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw -0.5vw 0.1vw #40340a;
    color: #806914; } }

@-webkit-keyframes button-flicker-3 {
  0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, -0.5vw 0.5vw 0.1vw #806914;
    color: #fff000; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, -0.5vw 0.5vw 0.1vw #40340a;
    color: #806914; } }

@keyframes button-flicker-3 {
  0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, -0.5vw 0.5vw 0.1vw #806914;
    color: #fff000; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, -0.5vw 0.5vw 0.1vw #40340a;
    color: #806914; } }

@-webkit-keyframes neon {
  0%, 1%, 3%, 5%, 48%, 51%, 53%, 80%, 82%, 100% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff; }
  2%, 4%, 50%, 52%, 81% {
    text-shadow: 0 0 0.5vw #0b1780, 0 0 1.5vw #0b1780, 0 0 5vw #0b1780, 0 0 5vw #0b1780, 0 0 0.2vw #0b1780, 0.5vw 0.5vw 0.1vw #260a40;
    color: #701480; } }

@keyframes neon {
  0%, 1%, 3%, 5%, 48%, 51%, 53%, 80%, 82%, 100% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff; }
  2%, 4%, 50%, 52%, 81% {
    text-shadow: 0 0 0.5vw #0b1780, 0 0 1.5vw #0b1780, 0 0 5vw #0b1780, 0 0 5vw #0b1780, 0 0 0.2vw #0b1780, 0.5vw 0.5vw 0.1vw #260a40;
    color: #701480; } }

@-webkit-keyframes neon-hover {
  0%, 5%, 10%, 40% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #0b1780, 0 0 1.5vw #0b1780, 0 0 5vw #0b1780, 0 0 5vw #0b1780, 0 0 0.2vw #0b1780, 0.5vw 0.5vw 0.1vw #260a40;
    color: #701480; }
  100% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #fabfff; } }

@keyframes neon-hover {
  0%, 5%, 10%, 40% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff; }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #0b1780, 0 0 1.5vw #0b1780, 0 0 5vw #0b1780, 0 0 5vw #0b1780, 0 0 0.2vw #0b1780, 0.5vw 0.5vw 0.1vw #260a40;
    color: #701480; }
  100% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #fabfff; } }

@-webkit-keyframes blackout {
  0%, 5%, 27%, 43%, 73%, 79%, 85% {
    opacity: 0; }
  2%, 8%, 25%, 42%, 70%, 76%, 82%, 88% {
    opacity: 1; }
  100% {
    opacity: 0;
    display: none; } }

@keyframes blackout {
  0%, 5%, 27%, 43%, 73%, 79%, 85% {
    opacity: 0; }
  2%, 8%, 25%, 42%, 70%, 76%, 82%, 88% {
    opacity: 1; }
  100% {
    opacity: 0;
    display: none; } }

@-webkit-keyframes flicker {
  from {
    opacity: 1; }
  4% {
    opacity: .9; }
  6% {
    opacity: .85; }
  8% {
    opacity: .95; }
  10% {
    opacity: .90; }
  11% {
    opacity: .922; }
  12% {
    opacity: .9; }
  14% {
    opacity: .95; }
  16% {
    opacity: .98; }
  17% {
    opacity: .9; }
  19% {
    opacity: .93; }
  20% {
    opacity: .99; }
  24% {
    opacity: 1; }
  26% {
    opacity: .94; }
  28% {
    opacity: .98; }
  37% {
    opacity: .93; }
  38% {
    opacity: .5; }
  39% {
    opacity: .96; }
  42% {
    opacity: 1; }
  44% {
    opacity: .97; }
  46% {
    opacity: .94; }
  56% {
    opacity: .9; }
  58% {
    opacity: .9; }
  60% {
    opacity: .99; }
  68% {
    opacity: 1; }
  70% {
    opacity: .9; }
  72% {
    opacity: .95; }
  93% {
    opacity: .93; }
  95% {
    opacity: .95; }
  97% {
    opacity: .93; }
  to {
    opacity: 1; } }

@keyframes flicker {
  from {
    opacity: 1; }
  4% {
    opacity: .9; }
  6% {
    opacity: .85; }
  8% {
    opacity: .95; }
  10% {
    opacity: .90; }
  11% {
    opacity: .922; }
  12% {
    opacity: .9; }
  14% {
    opacity: .95; }
  16% {
    opacity: .98; }
  17% {
    opacity: .9; }
  19% {
    opacity: .93; }
  20% {
    opacity: .99; }
  24% {
    opacity: 1; }
  26% {
    opacity: .94; }
  28% {
    opacity: .98; }
  37% {
    opacity: .93; }
  38% {
    opacity: .5; }
  39% {
    opacity: .96; }
  42% {
    opacity: 1; }
  44% {
    opacity: .97; }
  46% {
    opacity: .94; }
  56% {
    opacity: .9; }
  58% {
    opacity: .9; }
  60% {
    opacity: .99; }
  68% {
    opacity: 1; }
  70% {
    opacity: .9; }
  72% {
    opacity: .95; }
  93% {
    opacity: .93; }
  95% {
    opacity: .95; }
  97% {
    opacity: .93; }
  to {
    opacity: 1; } }

@media (max-width: 1140px) {
  .App > div {
    -webkit-flex-direction: column;
            flex-direction: column; }
  .App h1:nth-last-of-type(2) {
    display: none; }
  .App > .name > h1 {
    font-size: 13vw; } }

@media (max-width: 800px) {
  h2 {
    display: none; }
  .welcome-message {
    display: block; }
  .App > .menu-container {
    display: block; } }

html { font-family: 'Inter UI', sans-serif; }
@supports (font-variation-settings: normal) {
 html { font-family: 'Inter UI var alt', sans-serif; }
}
@font-face {
  font-family: neon;
  src: url(/static/media/neon1.1621a4e7.TTF);
}
@font-face {
  font-family: neon2;
  src: url(/static/media/neon2.75b55bbf.otf);
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
	box-sizing: border-box;
}
.about {
  background: #111;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 10vw;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .about .home-button {
    position: absolute;
    left: 2rem;
    top: 2rem;
    cursor: pointer; }
    .about .home-button i {
      text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
      color: #fed128; }
      .about .home-button i:hover {
        -webkit-animation: button-flicker-1 2s linear;
                animation: button-flicker-1 2s linear;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
  .about div {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .about h1 {
    display: inline-block;
    font-family: neon2, cursive;
    color: white;
    font-size: 2em;
    margin-top: 2em; }
  .about h3 {
    display: inline-block;
    font-family: sans-serif;
    -webkit-animation: none;
            animation: none;
    color: white;
    line-height: 5rem;
    font-size: 1.2rem;
    min-width: 300px; }

#piano {
  -webkit-filter: drop-shadow(0 0 2vw #cd669a) drop-shadow(0 0 0.4vw #fb9dcc) drop-shadow(0.5vw 0.5vw 0.1vw #b30c5f);
          filter: drop-shadow(0 0 2vw #cd669a) drop-shadow(0 0 0.4vw #fb9dcc) drop-shadow(0.5vw 0.5vw 0.1vw #b30c5f);
  width: 45%;
  min-width: 300px;
  margin: 3rem; }

.contact {
  background: #111;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .contact .contact-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse; }
  .contact .home-button {
    position: absolute;
    left: 2rem;
    top: 2rem;
    cursor: pointer; }
    .contact .home-button i {
      text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
      color: #fed128; }
      .contact .home-button i:hover {
        -webkit-animation: button-flicker-1 2s linear;
                animation: button-flicker-1 2s linear;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
  .contact h1 {
    display: inline-block;
    font-family: neon2, cursive;
    color: white;
    font-size: 2em;
    margin-bottom: 2em; }
  .contact a > i {
    width: 3rem;
    color: white;
    -webkit-transition: 1s;
    transition: 1s; }
    .contact a > i:hover {
      color: #fa1c16; }
  .contact .contact-text a,
  .contact .contact-text h3 {
    text-decoration: none;
    color: white;
    font-family: sans-serif;
    line-height: 1.5rem;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

#peace {
  width: 45%;
  min-width: 300px;
  margin: 3rem;
  -webkit-filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5);
          filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5); }

@media (max-width: 400px) {
  .contact > h1 {
    margin-bottom: 1rem; }
  #peace {
    min-width: auto;
    width: 80%; }
  .home-button i {
    font-size: 2rem; } }

@media (max-height: 825px) {
  #peace {
    width: auto;
    height: 60vh; }
  .contact > h1 {
    margin-bottom: 1rem; } }

.projects {
  background: #111;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .projects h1 {
    font-family: neon2, cursive;
    color: white;
    font-size: 1.2em;
    margin-top: 2em; }
  .projects > h1 {
    width: 100vw;
    font-size: 2em; }
  .projects .home-button {
    position: absolute;
    left: 2rem;
    top: 2rem;
    cursor: pointer; }
    .projects .home-button i {
      text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
      color: #fed128; }
      .projects .home-button i:hover {
        -webkit-animation: button-flicker-1 2s linear;
                animation: button-flicker-1 2s linear;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
  .projects .project-card {
    max-width: 35vw;
    text-align: center;
    margin: 2rem; }
    .projects .project-card .image {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      overflow: hidden;
      height: 300px;
      margin-bottom: 2rem;
      width: 100%;
      text-align: center; }
      .projects .project-card .image img {
        display: inline-block;
        height: 100%; }
    .projects .project-card h2 {
      font-family: neon2, cursive;
      font-size: 2em;
      margin-bottom: 1rem; }
    .projects .project-card h3 {
      font-family: neon2, cursive;
      font-size: 1em;
      color: white;
      line-height: 1.5rem; }
    .projects .project-card p, .projects .project-card a {
      color: white;
      font-family: sans-serif;
      text-align: left;
      margin-bottom: 1rem;
      line-height: 1.3rem; }
    .projects .project-card a {
      text-decoration: none;
      display: block;
      color: #f372ff;
      text-shadow: 0 0 1vw #162dfa77, 0 0 3vw #162dfa77, 0 0 10vw #162dfa77, 0 0 10vw #162dfa77, 0 0 0.4vw #f372ff77, 0.5vw 0.5vw 0.1vw #6e148077; }

@media (max-width: 1279px) {
  .projects > .project-card {
    max-width: 80vw; } }

.skills {
  background: #111;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .skills h1 {
    font-family: neon2, cursive;
    color: white;
    font-size: 1.2em;
    margin-top: 2em; }
  .skills > h1 {
    width: 100vw;
    font-size: 2em; }
  .skills .home-button {
    position: absolute;
    left: 2rem;
    top: 2rem;
    cursor: pointer; }
    .skills .home-button i {
      text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
      color: #fed128; }
      .skills .home-button i:hover {
        -webkit-animation: button-flicker-1 2s linear;
                animation: button-flicker-1 2s linear;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }

#html5 {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #fa1c16) drop-shadow(0 0 0.4vw #fed128) drop-shadow(0.5vw 0.5vw 0.1vw #806914);
          filter: drop-shadow(0 0 2vw #fa1c16) drop-shadow(0 0 0.4vw #fed128) drop-shadow(0.5vw 0.5vw 0.1vw #806914);
  /* animation: flicker 1s infinite; */ }

#css3 {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #1a16fa) drop-shadow(0 0 0.4vw #28fefe) drop-shadow(0.5vw 0.5vw 0.1vw #141680);
          filter: drop-shadow(0 0 2vw #1a16fa) drop-shadow(0 0 0.4vw #28fefe) drop-shadow(0.5vw 0.5vw 0.1vw #141680);
  /* animation: flicker 1s infinite; */ }

#javascript {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #ebfa16) drop-shadow(0 0 0.4vw #fafe28) drop-shadow(0.5vw 0.5vw 0.1vw #806314);
          filter: drop-shadow(0 0 2vw #ebfa16) drop-shadow(0 0 0.4vw #fafe28) drop-shadow(0.5vw 0.5vw 0.1vw #806314);
  /* animation: flicker 1s infinite; */ }

#react {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #86dde9) drop-shadow(0 0 0.4vw #45afcc) drop-shadow(0.5vw 0.5vw 0.1vw #076985);
          filter: drop-shadow(0 0 2vw #86dde9) drop-shadow(0 0 0.4vw #45afcc) drop-shadow(0.5vw 0.5vw 0.1vw #076985); }

#redux {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #f264ff) drop-shadow(0 0 0.4vw #b000c0) drop-shadow(0.5vw 0.5vw 0.1vw #62006b);
          filter: drop-shadow(0 0 2vw #f264ff) drop-shadow(0 0 0.4vw #b000c0) drop-shadow(0.5vw 0.5vw 0.1vw #62006b); }

#express {
  margin-bottom: -20px;
  margin-top: -20px;
  overflow: hidden;
  width: 140px;
  height: 140px;
  -webkit-filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5);
          filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5); }

#nodejs {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #adff9b) drop-shadow(0 0 0.4vw #32991b) drop-shadow(0.5vw 0.5vw 0.1vw #176805);
          filter: drop-shadow(0 0 2vw #adff9b) drop-shadow(0 0 0.4vw #32991b) drop-shadow(0.5vw 0.5vw 0.1vw #176805); }

#postgresql {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5);
          filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5); }

#sass {
  margin-bottom: -20px;
  margin-top: -20px;
  overflow: hidden;
  width: 140px;
  height: 140px;
  -webkit-filter: drop-shadow(0 0 2vw #cd669a) drop-shadow(0 0 0.4vw #fb9dcc) drop-shadow(0.5vw 0.5vw 0.1vw #b30c5f);
          filter: drop-shadow(0 0 2vw #cd669a) drop-shadow(0 0 0.4vw #fb9dcc) drop-shadow(0.5vw 0.5vw 0.1vw #b30c5f); }

#socket-io {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5);
          filter: drop-shadow(0 0 2vw #fff) drop-shadow(0 0 0.4vw #fff) drop-shadow(0.5vw 0.5vw 0.1vw #a5a5a5); }

.html5,
.css3,
.javascript,
.react,
.redux,
.express,
.nodejs,
.postgresql,
.socket-io,
.sass {
  display: inline-block;
  margin: 2rem 5rem; }

