body {
	background: #111;
}

.fade-enter,
.fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  transition: 1s ease opacity;
}
.fade-enter,
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  z-index: 1;
}

.hide-loading {
  display: none;
}
/* for showing/hiding pages */
.show {
  pointer-events: all;
  opacity: 1;
  transition: 1s;
  height: auto;
  overflow: auto;
}
.hide {
  opacity: 0;
  pointer-events: none;
  transition: 1s;
  height: 0px;
  overflow: hidden;
}

.background {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: .03;
	background: url('./assets/neon\ background\ better.svg');
	background-size: cover;
}

.App {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
  background-size: cover;
  .menu-container {
    color: #fafafa;
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 2;
    display: none;
  }
  .fullscreen-menu {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #333333ee;
    border-radius: 50% 0 0 50%;
    // height: 0;
    min-height: 100%;
    width: 0;
    overflow: hidden;
    transition: 1s;
    h3 {
      color: white;
      font-family: neon2, cursive;
      font-size: 10vmin;
      line-height: 20vmin;
      &:hover {
        color: #fed128;
      }
    }
    &.show {
      width: 100vw;
      border-radius: 0;
      overflow: scroll;
    }
  }
  > h2 {
    position: absolute;
    font-family: neon2, cursive;
    font-size: calc(1em + 1vw);
    // z-index: 1;
    width: 200px;
    cursor: pointer;
    background: #111;
    padding: 15px;
    &:nth-last-of-type(1) {
      z-index: 0;
    }
  }
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    > h1 {margin: 0;
    font-family: neon, cursive;	
    font-size: 6.5vw;
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff;
    animation: neon 10s linear infinite alternate-reverse;
    animation: flicker 1s infinite;
    }
    &:hover h1 {
      animation: neon-hover 3s linear;
      animation-fill-mode: both;
      cursor: default;
    }
    .blackout {
      animation: blackout 1.5s linear;
      animation-fill-mode: forwards;
    }
  }
  h2:nth-last-of-type(1) {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff;
    animation: neon 10s linear infinite alternate-reverse;
    animation: flicker 1s infinite;
    animation-delay: .2s;
  }
  .welcome-message {
    color: white;
    width: auto;
    position: static;
    margin-top: 10vh;
    cursor: default;
  }
  .blackout {
    animation: blackout 1.5s linear !important;
    animation-fill-mode: forwards !important;
  }
  > .top {
    left: 50%;
    transform: translate(-50%, 0);
    top: 20px;
  }
  > .bottom {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
  }
  > .right {
    right: -50px;
    top: 50%;
    transform: rotate(90deg);
  }
  > .left {
    left: -60px;
    top: 50%;
    transform: rotate(-90deg)
  }
  .top, .bottom {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
    &:hover {
      animation: button-flicker-1 2s linear;
      animation-fill-mode: forwards;
    }
  }
  .right {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw -0.5vw 0.1vw #806914;
    color: #fed128;
    &:hover {
      animation: button-flicker-2 2s linear;
      animation-fill-mode: forwards;
    }
  }
  .left {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, -0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
    &:hover {
      animation: button-flicker-3 2s linear;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes button-flicker-1 {
	0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fff000;
  }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}
@keyframes button-flicker-2 {
	0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw -0.5vw 0.1vw #806914;
    color: #fff000;
  }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw -0.5vw 0.1vw #40340a;
    color: #806914;
  }
}
@keyframes button-flicker-3 {
	0%, 5%, 10%, 40%, 100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, -0.5vw 0.5vw 0.1vw #806914;
    color: #fff000;
  }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, -0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}
@keyframes neon {
  0%, 1%, 3%, 5%, 48%, 51%, 53%, 80%, 82%, 100% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff;
  }
  2%, 4%, 50%, 52%, 81% {
    text-shadow: 0 0 0.5vw #0b1780, 0 0 1.5vw #0b1780, 0 0 5vw #0b1780, 0 0 5vw #0b1780, 0 0 0.2vw #0b1780, 0.5vw 0.5vw 0.1vw #260a40;
    color: #701480;
  }
}
@keyframes neon-hover {
  0%, 5%, 10%, 40% {
    text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #f372ff;
  }
  2%, 7%, 13% {
    text-shadow: 0 0 0.5vw #0b1780, 0 0 1.5vw #0b1780, 0 0 5vw #0b1780, 0 0 5vw #0b1780, 0 0 0.2vw #0b1780, 0.5vw 0.5vw 0.1vw #260a40;
    color: #701480;
	}
	100% {
		text-shadow: 0 0 1vw #162dfa, 0 0 3vw #162dfa, 0 0 10vw #162dfa, 0 0 10vw #162dfa, 0 0 0.4vw #f372ff, 0.5vw 0.5vw 0.1vw #6e1480;
    color: #fabfff;
	}
}

@keyframes blackout {
  0%, 5%, 27%, 43%, 73%, 79%, 85% {
    opacity: 0
  }
  2%, 8%, 25%, 42%, 70%, 76%, 82%, 88% {
    opacity: 1
	}
	100% {
    opacity: 0;
    display: none;
	}
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: .9;
  }

  6% {
    opacity: .85;
  }

  8% {
    opacity: .95;
  }

  10% {
    opacity: .90;
  }

  11% {
    opacity: .922;
  }

  12% {
    opacity: .9;
  }

  14% {
    opacity: .95;
  }

  16% {
    opacity: .98;
  }

  17% {
    opacity: .9;
  }

  19% {
    opacity: .93;
  }

  20% {
    opacity: .99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: .94;
  }

  28% {
    opacity: .98;
  }

  37% {
    opacity: .93;
  }

  38% {
    opacity: .5;
  }

  39% {
    opacity: .96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: .97;
  }

  46% {
    opacity: .94;
  }

  56% {
    opacity: .9;
  }

  58% {
    opacity: .9;
  }

  60% {
    opacity: .99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: .9;
  }

  72% {
    opacity: .95;
  }

  93% {
    opacity: .93;
  }

  95% {
    opacity: .95;
  }

  97% {
    opacity: .93;
  }

  to {
    opacity: 1;
  }
}


@media(max-width: 1140px) {
  .App > div {
    flex-direction: column;
  }
  .App h1:nth-last-of-type(2){
    display:none
  }
  .App > .name > h1 {
    font-size: 13vw;
  }
}

@media(max-width: 800px) {
  h2 {
    display: none;
  }
  .welcome-message {
    display: block;
  }
  .App > .menu-container {
    display: block;
  }
}